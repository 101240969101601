<template>
  <Tooltip>
    <TooltipTrigger>
      <span :class="['parent  no-wrap flex', horizontal ? 'gap-2' : 'column ']">
        <span>{{ timeAgo }}</span>
      </span>
    </TooltipTrigger>
    <TooltipContent class="text-caption">
      <RenderDateTime :time="serialisedTime" :format="format" />
    </TooltipContent>
  </Tooltip>
</template>

<script setup lang="ts">
import { getTimeAgo } from "~/utils/dateHelpers";
import { RenderDateTime } from "#components";
import { computed } from "vue";
import {
  deserialiseDate,
  type SerialisedDate,
} from "~/src/models/utils/Serialise.model";

const props = defineProps<{
  time?: Date | SerialisedDate;
  alwaysDisplay?: boolean;
  horizontal?: boolean;
  hideTime?: boolean;
  format?: string;
  hover?: boolean;
  strict?: boolean;
}>();

const serialisedTime = computed(() =>
  typeof props.time === "string" ? deserialiseDate(props.time) : props.time
);

const timeAgo = computed(() => {
  if (serialisedTime.value)
    return getTimeAgo(serialisedTime.value, props.strict ?? true);
  return " - ";
});
</script>

<style lang="scss" scoped>
.formattedTime {
  margin-top: -4px;
  display: none;
  font-size: 12px;
}

.parent:hover {
  .formattedTime {
    display: block;
  }
}
</style>
